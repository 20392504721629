.disabled-row {
    background-color: #F0F0F0;
    pointer-events: none;
  }
.uneditable-row {
    pointer-events: none;
  }
thead > tr > .name-column {
  padding: 0px 50px !important;
}
h4{
  text-align: center;
}
.ant-pro-table .ant-table{
overflow-x: auto;
}

.hidden {
  display: none;
}
thead,tbody, td>p,th>span {
  font-size: 14px;
}

.ant-upload.ant-upload-select-picture-card {
  height: 90px;
  width: 90px;
}

@primary-color: #2F54EB;@border-radius-base: 12px;@font-size-base: 18px;