/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.popover {
  position: relative;
  width: 400px;
}
.noticeButton {
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}
.icon {
  padding: 4px;
  vertical-align: middle;
}
.badge {
  font-size: 16px;
}
.tabs .ant-tabs-nav-list {
  margin: auto;
}
.tabs .ant-tabs-nav-scroll {
  text-align: center;
}
.tabs .ant-tabs-bar {
  margin-bottom: 0;
}
