/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.login-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: #f0f2f5;
}
.lang {
  width: 100%;
  height: 40px;
  line-height: 44px;
  text-align: right;
}
.lang :global(.ant-dropdown-trigger) {
  margin-right: 24px;
}
.login-content {
  flex: 1;
  padding: 32px 0;
}
@media (min-width: 768px) {
  .login-container {
    background-image: url("https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg");
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }
  .login-content {
    padding: 32px 0 24px;
  }
}
.icon {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.2);
  font-size: 24px;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
}
.icon:hover {
  color: #2F54EB;
}
