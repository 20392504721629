.uneditable-row {
    pointer-events: none;
  }
// thead > tr > .name-column {
//   padding: 0px 50px !important;
// }
// h4{
//   text-align: center;
// }
// .ant-pro-table .ant-table{
// overflow-x: auto;
// }
@primary-color: #2F54EB;@border-radius-base: 12px;@font-size-base: 18px;