/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.cameraimage {
  height: 100%;
  width: auto;
  object-fit: contain;
}
.camera video {
  height: 100%;
}
.dragger {
  min-height: 50vh;
}
@media (max-width: 480px) {
  .order_radio {
    display: flex !important;
    flex-direction: column !important;
  }
  .ant-radio-button-wrapper {
    margin: 5px 0px;
  }
}
