/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.avatarHolder {
  margin-bottom: 24px;
  text-align: center;
}
.avatarHolder > img {
  width: 104px;
  height: 104px;
  margin-bottom: 20px;
}
.avatarHolder .name {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
.detail p {
  position: relative;
  margin-bottom: 8px;
  padding-left: 26px;
}
.detail p:last-child {
  margin-bottom: 0;
}
.detail i {
  position: absolute;
  top: 4px;
  left: 0;
  width: 14px;
  height: 14px;
}
.tagsTitle,
.teamTitle {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
.tags :global .ant-tag {
  margin-bottom: 8px;
}
.team :global .ant-avatar {
  margin-right: 12px;
}
.team a {
  display: block;
  margin-bottom: 24px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  transition: color 0.3s;
}
.team a:hover {
  color: #2F54EB;
}
.tabsCard :global .ant-card-head {
  padding: 0 16px;
}
