/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.baseView {
  display: flex;
  padding-top: 12px;
}
.baseView :global .ant-legacy-form-item .ant-legacy-form-item-control-wrapper {
  width: 100%;
}
.baseView .base-left {
  min-width: 224px;
  max-width: 448px;
}
.baseView .base-right {
  flex: 1;
  padding-left: 104px;
}
.baseView .base-right .avatar_title {
  height: 22px;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  line-height: 22px;
}
.baseView .base-right .avatar {
  width: 200px;
  height: 200px;
  margin-bottom: 12px;
  overflow: hidden;
}
.baseView .base-right .avatar img {
  width: 100%;
  border-radius: 20px;
}
.baseView .base-right .button_view {
  width: 144px;
  text-align: center;
}
.area_code {
  width: 72px;
}
.phone_number {
  width: 214px;
}
@media screen and (max-width: 1200px) {
  .baseView {
    flex-direction: column-reverse;
  }
  .baseView .base-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 448px;
    padding: 20px;
  }
  .baseView .base-right .avatar_title {
    display: none;
  }
}
