/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.main :global .ant-descriptions-row > td {
  padding-bottom: 8px;
}
.main :global .ant-page-header-heading-extra {
  flex-direction: column;
}
.ant-card-head-title {
  overflow: visible;
}
.headerList {
  margin-bottom: 4px;
}
.headerList :global .ant-descriptions-row > td {
  padding-bottom: 8px;
}
.headerList .stepDescription {
  position: relative;
  left: 38px;
  padding-top: 8px;
  font-size: 14px;
  text-align: left;
}
.headerList .stepDescription > div {
  margin-top: 8px;
  margin-bottom: 4px;
}
.pageHeader :global .ant-page-header-heading-extra > * + * {
  margin-left: 8px;
}
.pageHeader .moreInfo {
  display: flex;
  justify-content: space-between;
}
.new_order_container {
  overflow: hidden;
  width: 90vw;
}
@media screen and (max-width: 576px) {
  .stepDescription {
    left: 8px;
  }
  .pageHeader :global .ant-pro-page-header-wrap-row {
    flex-direction: column;
  }
  .new_order_column {
    max-width: 300px;
  }
  .ant-modal {
    width: 90% !important;
  }
}
.OrderProductInput {
  border: none;
}
