.site-statistic-demo-card {
    padding: 20px;
    background: white;
    margin: 5px;
  }
.ant-badge-status-dot{
    width: "10px" !important;
    height: "10px" !important;
}

@media screen and (max-width:700px) {
    .dashboard_statistic{
        width:100px
    }
  }

/* .ant-row-start {
    margin: 1%  !important;
} */
