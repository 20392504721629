/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.menu .anticon {
  margin-right: 8px;
}
.menu .ant-dropdown-menu-item {
  min-width: 160px;
}
.sync_zoho {
  cursor: pointer;
}
.right {
  display: flex;
  float: right;
  height: 48px;
  margin-left: auto;
  overflow: hidden;
}
.right .action {
  cursor: pointer;
  transition: all 0.3s;
}
.right .action > span {
  vertical-align: middle;
}
.right .action:hover {
  background: rgba(0, 0, 0, 0.025);
}
.right .action.opened {
  background: rgba(0, 0, 0, 0.025);
}
.right .search {
  padding: 0 12px;
}
.right .search:hover {
  background: transparent;
}
.right .account .avatar {
  margin-right: 8px;
  color: #2F54EB;
  vertical-align: top;
  background: rgba(255, 255, 255, 0.85);
}
.dark .action:hover {
  background: #252a3d;
}
.dark .action.opened {
  background: #252a3d;
}
@media only screen and (max-width: 768px) {
  .ant-divider-vertical {
    vertical-align: unset;
  }
  .name {
    display: none;
  }
  .right {
    position: absolute;
    top: 0;
    right: 12px;
  }
  .right .account .avatar {
    margin-right: 0;
  }
  .right .search {
    display: none;
  }
}
